import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/index/home'
  },
  {
    path: '/index',
    name: 'layout',
    component: () => import(/* webpackChunkName: "layout" */ '../views/layout.vue'),
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/home.vue')
      },
      {
        path: 'services',
        name: 'services',
        component: () => import(/* webpackChunkName: "services" */ '../views/services.vue')
      },
      {
        path: 'servicesdetail',
        name: 'servicesdetail',
        component: () => import(/* webpackChunkName: "servicesdetail" */ '../views/servicesdetail.vue')
      },
      {
        path: 'news',
        name: 'news',
        component: () => import(/* webpackChunkName: "news" */ '../views/news.vue')
      },
      {
        path: 'newsdetail',
        name: 'newsdetail',
        component: () => import(/* webpackChunkName: "newsdetail" */ '../views/newsdetail.vue')
      },
      {
        path: 'aboutus',
        name: 'aboutus',
        component: () => import(/* webpackChunkName: "aboutus" */ '../views/aboutus.vue')
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import(/* webpackChunkName: "contact" */ '../views/contact.vue')
      },
      {
        path: 'cart',
        name: 'cart',
        component: () => import(/* webpackChunkName: "cart" */ '../views/cart.vue')
      },
      {
        path: 'search',
        name: 'search',
        component: () => import(/* webpackChunkName: "search" */ '../views/search.vue')
      },
      {
        path: 'form',
        name: 'form',
        component: () => import(/* webpackChunkName: "form" */ '../views/form.vue')
      },
      {
        path: 'applyrecord',
        name: 'applyrecord',
        component: () => import(/* webpackChunkName: "applyrecord" */ '../views/applyrecord.vue')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
