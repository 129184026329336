<template>
  <div class="loginpopout"
    v-if="show">
    <div class="loginpop flc">
      <img class="close" :src="close"
        @click="closepop"/>
      <img class="logo" :src="shield" />
      <div class="title">Sign In to Your Account</div>
      <div class="sub">Access Your Profile,Order Status and saved Progress</div>
      <div class="form">
        <div class="label">Email or username</div>
        <input class="input" v-model="form.name" />
        <div class="label">Password</div>
        <input class="input" type="password"
          v-model="form.password" />
        <div class="label">Code</div>
        <div class="lineinput">
          <input class="input"
            v-model="form.code" />
          <img @click="getCode"  :src="codeUrl" />
        </div>
        <div class="line">
          <img :src="checked" />
          Remember User Name
        </div>
      </div>
      <!-- <div class="rigiout">
        <div class="rigi"
          @click="regi = !regi"
          >
          {{regi ? 'to Login' : 'to Register'}}
        </div>
      </div> -->
      <div class="btns">
        <div class="btn white"
          @click="closepop">
          Cancel
        </div>
        <div class="btn"
          v-if="regi"
          @click="signfn">
          Sign
        </div>
        <div class="btn"
          v-if="!regi"
          @click="loginfn">
          Login
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import shield from '@/assets/shield.svg'
import close from '@/assets/close.svg'
import checked from '@/assets/checked.svg'
import { register, getCodeImg, login } from '@/api/login.js'
import { setToken } from '@/utils/auth.js'
export default {
  name: 'loginPop',
  data () {
    return {
      shield,
      close,
      checked,
      show: false,
      form: {
        name: '',
        password: '',
        uuid: '',
        code: ''
      },
      codeUrl: '',
      regi: false
    }
  },
  async mounted () {
    this.getCode()
    this.getState()
  },
  methods: {
    getCode () {
      getCodeImg().then(res => {
        this.captchaEnabled = res.captchaEnabled === undefined ? true : res.captchaEnabled
        if (this.captchaEnabled) {
          this.codeUrl = 'data:image/gif;base64,' + res.img
          this.form.uuid = res.uuid
        }
      })
    },
    getState () {
      const uname = localStorage.getItem('yz_username')
      const upass = localStorage.getItem('yz_password')
      if (uname && upass) {
        this.regi = true
      }
    },
    closepop () {
      this.form = {}
      this.show = false
    },
    async signfn () {
      await register({
        username: this.form.name,
        password: this.form.password,
        confirmPassword: this.form.password,
        code: this.form.code,
        uuid: this.form.uuid
      })
      // localStorage.setItem('yz_username', this.form.name)
      // localStorage.setItem('yz_password', this.form.password)
      this.getCode()
      this.getState()
      this.$message.success('sign success and please login')
      this.form = {}
      this.regi = false
    },
    async loginfn () {
      const res = await login(
        this.form.name,
        this.form.password,
        this.form.code,
        this.form.uuid
      )
      // localStorage.setItem('yz_token', res.token)
      setToken(res.token)
      this.$message.success('login success')
      this.show = false
      this.$emit('login')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.loginpopout {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, .8);
  .loginpop {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 400px;
    height: 550px; // 470px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    .logo {
      margin-bottom: 20px;
    }
    .title {
      color: #101828;
      font-size: 20px;
      margin-bottom: 10px;
    }
    .sub {
      color: #475467;
      font-size: 14px;
      margin-bottom: 20px;
    }
    .form {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
    }
    .label {
      font-size: 12px;
      margin-bottom: 5px;
    }
    .lineinput {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      img {
        height: 44px;
        width: auto;
        margin-left: 10px;
      }
      .input {
        flex: 1;
        width: auto;
      }
    }
    .input {
      width: 352px;
      height: 44px;
      padding: 0 20px;
      font-size: 16px;
      color: #3d3d3d;
      box-sizing: border-box;
      margin-bottom: 20px;
    }
    .line {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .check {
        margin-right: 10px;
      }
    }
    .rigiout {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
    }
    .rigi {
      color: skyblue;
      text-align: left;
      float: left;
      padding: 5px 16px;
      cursor: pointer;
    }
    .btns {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      .btn {
        width: 170px;
        height: 44px;
        background-color: #121212;
        font-size: 16px;
        color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 7px;
          cursor: pointer;
        &.white {
          border: 1px solid #ccc;
          color: #121212;
          background-color: #fff;
        }
      }
    }
    .close {
      position: absolute;
      z-index: 1;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }
}

@media (max-width: 768px) {

  .loginpopout {
    .loginpop {
      width: 350px;
      height: 450px; // 470px;
      padding: 10px;
      box-sizing: border-box;
      .logo {
        margin-bottom: 10px;
      }
      .title {
        font-size: 16px;
        margin-bottom: 5px;
      }
      .sub {
        font-size: 12px;
        margin-bottom: 10px;
      }
      .form {
      }
      .label {
        font-size: 12px;
        margin-bottom: 5px;
      }
      .lineinput {
        img {
          height: 32px;
          width: auto;
          margin-left: 5px;
        }
        .input {
        }
      }
      .input {
        width: 330px;
        height: 32px;
        padding: 0 10px;
        font-size: 12px;
        margin-bottom: 10px;
      }
      .line {
        font-size: 12px;
        .check {
          margin-right: 10px;
        }
      }
      .rigiout {
      }
      .rigi {
        padding: 5px 10px;
      }
      .btns {
        margin-top: 10px;
        .btn {
          width: 120px;
          height: 32px;
          font-size: 12px;
        }
      }
      .close {
      }
    }
  }
}
</style>
